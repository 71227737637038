.grid-container-busqueda {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 250px 550px 1fr;
  grid-template-areas: "lbl_filtros" "lbl_detalle_tabla" "lbl_ficha_activa";
}

.lbl_filtros { grid-area: lbl_filtros; }

.lbl_detalle_tabla { grid-area: lbl_detalle_tabla; }

.lbl_ficha_activa { grid-area: lbl_ficha_activa; }

.lbl_color{
  color: white
}
